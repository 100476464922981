<template>
  <div>
    <h5 class="mx-4 font-bold">製造與作廢紀錄</h5>
    <DataTable class="mx-4" :value="manufactAndScrap" responsiveLayout="scroll">
      <Column field="datetime" header="時間"></Column>
      <Column field="" header="" :style="{width:'96px'}">
        <template #body="slotProps">
          <Tag v-if="slotProps.data.tag == '製造'" class="mr-2" severity="success" :value="slotProps.data.tag"></Tag>
          <Tag v-if="slotProps.data.tag == '報廢'" class="mr-2" severity="warning" :value="slotProps.data.tag"></Tag>
        </template>
      </Column>
      <Column field="product_consumed" header="">
        <template #body="slotProps">
          {{ slotProps.data.product_name }} {{ slotProps.data.quantity }} {{ slotProps.data.uom }}
        </template>
      </Column>
    </DataTable>
    <hr>
    <h5 class="mx-4 font-bold">採購紀錄</h5>
    <DataTable class="mt-4 mx-4" :value="purchaseList" responsiveLayout="scroll">
      <Column field="datetime" header="時間"></Column>
      <Column field="" header="" :style="{width:'96px'}">
        <template #body="slotProps">
          <Tag class="mr-2" severity="success" :value="slotProps.data.tag"></Tag>
        </template>
      </Column>
      <Column field="product_consumed" header="">
        <template #body="slotProps">
          {{ slotProps.data.product.product_name }} {{ slotProps.data.product.quantity }} {{ slotProps.data.product.uom
          }}
        </template>
      </Column>
    </DataTable>

    <h5 class="mx-4 font-bold">盤點紀錄</h5>
    <DataTable class="mt-4 mb-6 mx-4" :value="inventoryAdjustmentsList" responsiveLayout="scroll">
      <Column field="datetime" header="時間"></Column>
      <Column field="" header="" :style="{width:'96px'}">
        <template #body="slotProps">
          <Tag class="mr-2" severity="info" :value="slotProps.data.tag"></Tag>
        </template>
      </Column>
      <Column field="product_consumed" header="">
        <template #body="slotProps">
          {{ slotProps.data.product_name }} {{ slotProps.data.quantity }} {{ slotProps.data.uom }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios"
// import { reactive } from "vue"

export default {
  data() {
    return {
      purchase_orders: [
        {
          purchase_datetime: '2022/09/24 15:03',
          products: [
            {
              product_name: '賞紅茶葉',
              quantity: 500,
              uom: 'g'
            }
          ]
        }
      ],
      manufacture_orders: [
        {
          manufacture_datetime: "2022/09/25 8:03",
          product_out_id: 2,
          product_out_name: '賞紅茶',
          product_consumed: {
            quantity: 1000,
            uom: 'mL'
          }
        },
        {
          manufacture_datetime: "2022/09/25 10:15",
          product_out_id: 2,
          product_out_name: '賞紅茶',
          product_consumed: {
            quantity: 3000,
            uom: 'mL'
          }
        },
        {
          manufacture_datetime: "2022/09/25 12:13",
          product_out_id: 2,
          product_out_name: '賞紅茶',
          product_consumed: {
            quantity: 3500,
            uom: 'mL'
          }
        }
      ],
      scrap_orders: [
        {
          scrap_datetime: "2022/09/25 19:13",
          product_id: 2,
          product_name: '賞紅茶',
          quantity: 500,
          uom: 'mL'
        }
      ],
      inventory_adjustments: []
    }
  },
  computed: {
    manufactAndScrap() {
      let list = []
      this.manufacture_orders.forEach(manu => {
        list.push({
          datetime: manu.manufacture_datetime,
          product_name: manu.product_out_name,
          quantity: manu.product_consumed.quantity,
          uom: manu.product_consumed.uom,
          tag: '製造'
        })
      })
      this.scrap_orders.forEach(scrap => {
        list.push({
          datetime: scrap.scrap_datetime,
          product_name: scrap.product_name,
          quantity: scrap.quantity,
          uom: scrap.uom,
          tag: '報廢'
        })
      })
      return list
    },
    purchaseList() {
      let list = []
      this.purchase_orders.forEach(pur => {
        list.push({
          datetime: pur.purchase_datetime,
          product: pur.products[0],
          tag: '採購'
        })
      })
      return list
    },
    inventoryAdjustmentsList() {
      let list = []
      this.inventory_adjustments.forEach(inv => {
        list.push({
          datetime: inv.adjustment_datetime,
          product: inv.product_name,
          quantity: inv.counted_quantity,
          uom: inv.uom,
          tag: '盤點'
        })
      })
      return list
    }
  },
  created() {
    axios("/api/get_overview", {
      method: "POST",
      withCredentials: true,
      params: {
        token: "0000",
      },
      data: {
        start_datetime: "2022-09-02",
        end_datetime: "2022-10-02"
      }
    })
      .then((response) => {
        this.purchase_orders = response.data.purchase_orders
        this.manufacture_orders = response.data.manufacture_orders
        this.scrap_orders = response.data.scrap_orders
        this.inventory_adjustments = response.data.inventory_adjustments
      })
      .catch((err) => {
        console.log(err.response)
      })
  },
}
</script>

<style lang="scss" scoped>
.p-tag-success {
  background-color: #c8e6c9;
  color: #256029;
}

.p-tag-warning {
  background-color: #ffcdd2;
  color: #c63737;
}

.p-tag-info {
  background-color: #b3e5fc;
  color: #23547b;
}
</style>